<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="projectData === undefined">
      <h4 class="alert-heading">{{ $t("message.errorLoadingData") }}</h4>
      <div class="alert-body">
        {{ $t("message.projects.errorProjects") }}
        <b-link class="alert-link" :to="{ name: 'apps-project-list' }">
          {{ $t("message.projects.listOfProjects") }}
        </b-link>
        {{ $t("message.projects.forOtherProjects") }}
      </div>
    </b-alert>

    <template v-if="projectData">
      <div class="cardTableProject">
        <b-card>
          <!-- editar projeto -->
          <h2 class="mb-2">{{ $t("message.projects.project") }}</h2>
          <b-row>
            <table class="widthTable rTable">
              <td class="widthTd rTableWidth">
                <b-col md="12">
                  <b-form-group label-for="nameProjects">
                    <template v-slot:label>
                      {{ $t("message.tableHeader.name") }}
                    </template>
                    <b-form-input
                      readonly
                      v-model="projectData.name"
                      label="name"
                      label-for="nameProjects"
                    />
                  </b-form-group>
                </b-col>

                <tr>
                  <td>
                    <b-col md="12">
                      <b-form-group label-for="createdDate">
                        <template v-slot:label>
                          {{ $t("message.date.start") }}
                        </template>
                        <flat-pickr
                          id="created-date"
                          v-model="projectData.startDate"
                          class="form-control"
                          :config="{
                            altFormat: 'd/m/Y',
                            altInput: true,
                            format: 'd/m/Y',
                          }"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </td>
                  <td>
                    <b-col md="12">
                      <b-form-group label-for="endDate">
                        <template v-slot:label>
                          {{ $t("message.date.end") }}
                        </template>
                        <flat-pickr
                          id="end-date"
                          v-model="projectData.endDate"
                          class="form-control"
                          :config="{
                            altFormat: 'd/m/Y',
                            altInput: true,
                            format: 'd/m/Y',
                          }"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </td>
                  <td>
                    <b-col md="12">
                      <b-form-group label-for="deadlineTasks">
                        <template v-slot:label>
                          {{ $t("message.projects.estimatedTime") }}
                        </template>
                        <b-form-input
                          readonly
                          id="number"
                          type="number"
                          v-model="projectData.estimatedTime"
                        />
                      </b-form-group>
                    </b-col>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b-col md="12">
                      <b-form-group label-for="teamsId">
                        <template v-slot:label>
                          {{ $t("message.team.team") }}
                        </template>
                        <b-form-input
                          readonly
                          v-model="projectData.name"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="teams"
                        />
                      </b-form-group>
                    </b-col>
                  </td>
                  <td>
                    <b-col md="12">
                      <b-form-group label-for="typeProjects">
                        <template v-slot:label>
                          {{ $t("message.tableHeader.status") }}
                        </template>
                        <b-form-input
                          readonly
                          v-model="projectData.status"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="type"
                        />
                      </b-form-group>
                    </b-col>
                  </td>
                </tr>
              </td>
              <td class="widthTd rTableWidth">
                <b-col md="12" class="mt">
                  <b-form-group label-for="descriptionTasks">
                    <template v-slot:label>
                      {{ $t("message.projects.description") }}
                    </template>
                    <b-form-textarea
                      readonly
                      v-model="projectData.description"
                      label="descriptionTasks"
                      rows="8"
                    /><br />
                  </b-form-group>
                </b-col>
              </td>
              <td></td>
            </table>
          </b-row>

          <b-row>
            <b-col>
              <div class="text-right mr-2 mb-2 rButton">
                <b-button
                  :to="{
                    name: 'apps-project-edit',
                    params: { id: projectData.id },
                  }"
                  variant="primary"
                >
                  {{ $t("message.buttons.edit") }}
                </b-button>
                <b-button
                  v-b-modal.modal-delete-confirmation
                  variant="outline-danger"
                  class="ml-1"
                >
                  {{ $t("message.buttons.delete") }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <b-modal
        id="modal-delete-confirmation"
        modal-class="modal-danger"
        centered
        :title="$t('delete')"
        hide-footer
      >
        <b-card-text>
          {{ $t("message.deletAcount") }}
        </b-card-text>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <b-button
            v-b-modal.modal-danger
            class="mt-2"
            variant="danger"
            :to="{ name: 'apps-project-list' }"
            @click="deleteProject()"
          >
            {{ $t("message.buttons.delete") }}
          </b-button>
        </div>
      </b-modal>
    </template>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BCardText,
  BCardGroup,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BCardText,
    BCardGroup,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    flatPickr,
  },

  data() {
    return {
      //multilingual i18n \\
      locale: this.$i18n.locale,
      userData: store.state.user.userData,
    };
  },

  watch: {
    locale(val) {
      this.$i18n.locale = val;
    },
  },

  setup() {
    const projectData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}project/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        projectData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          projectData.value = undefined;
        }
      });

    return {
      projectData,
    };
  },

  methods: {
    deleteProject() {
      axios({
        method: "delete",
        url: `${URL_API}project/${this.projectData.id}`,
        headers: getHeader(this.userData),
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.deleted"),
              icon: "CheckIcon",
              variant: "success",
              text: `O projeto ${this.projectData.name} foi deletado com sucesso`,
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            projectData.value = undefined;
          }
        });
    },
  },
};
</script>

<style>
.mt {
  margin-top: -0.5rem;
}

.widthTable {
  width: 100%;
}

.widthTd {
  width: 50%;
}

.plInput {
  padding-left: 20rem;
}

@media screen and (max-width: 767px) {
  .cardTableProject {
    width: 100%;
  }

  .rTable td {
    display: flex;
    flex-direction: column;
  }
  .rTableWidth {
    width: 100%;
  }

  .rButton {
    margin-top: 3rem;
  }
}
</style>
